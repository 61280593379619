.fontsize h1{
    font-size: 60px;
}


.contactbutton {
    background-color: #AF1E6B; /* Green */
    border: none;
    color: white;
    padding: 7px 19px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
  }

  .textcolor h3{
    color: #9b0957;
  }
  .textcolor h5{
    color: #9b0957;
  }



  .btn-grad33 {
    background-image: linear-gradient(to right, #AF1E6B 0%, #e34cb6  51%, #044c80  100%);
    margin: 0px;
    padding: 6px 20px;
    text-align: center;
    /* text-transform: uppercase; */
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 1px;
    display: block;
  }
  
  .btn-grad33:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }