.traditionaljagadhatripuja{
    background-color: #AF1E6B;
}



.btn-grad56 {
    background-image: linear-gradient(to right, #AF1E6B 0%, #e34cb6  51%, #044c80  100%);
    margin: 0px;
    padding: 6px 20px;
    text-align: center;
    /* text-transform: uppercase; */
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 6px;
    display: block;
  }
  
  .btn-grad56:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }