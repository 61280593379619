

.kps{
    background-color : #AF1E6B;

}

.krishnagarjagadhatri{
    background-color: #EC0D8C; /* Green */
    border: none;
    color: white;
    padding: 7px 22px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 5px;
  }
  