.krishnagarjagadhatritraditionalpuja{
    background-color: rgb(252, 225, 241);
}

.no1restuarantkrishnagar{
    background-color: rgb(231, 247, 250);
}

.maajagadhatri{
    height:  400px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-image: url(https://i.postimg.cc/JnWD23tV/2021-10-13.jpg);
    background-attachment: fixed;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Josefin Sans', sans-serif;
}